import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"
const SpecialProjects = ({ data }) => (
  <Layout>
    <Seo
      title="Special Projects"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Special Projects</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              Growth and change are constant in running your business and having
              a qualified set of resources to manage that change is the basis
              for success. If you are a current client, we are already intimate
              with your internal operations and how that change will impact your
              existing operations. If you are seeking a new resource for dealing
              with your special project, why not work with a company who has a
              proven track record in creating satisfied customers.
            </p>
            <ul>
              <li>Voice &amp; Data Circuits</li>
              <li>Cabling</li>
              <li>Network Design and Installation</li>
              <li>Servers, Desktops, Laptops &amp; Systems</li>
              <li>Mobile Device Management</li>
              <li>Application Software Implementations</li>
              <li>Office 365 Implementations</li>
              <li>Virtualization</li>
              <li>Paperless Office - Scanning</li>
              <li>Process Engineering</li>
              <li>Cloud Files - Sync and Share</li>
              <li>Web Design</li>
              <li>VoIP Implementations</li>
            </ul>
            <p>
              Regardless of the project, as your business grows and your needs
              change, we are the consistent resource you can rely on to take on
              the task. Let us show you how we build happy customers.
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SpecialProjects

export const SpecialProjectsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
